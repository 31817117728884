<template>
  <div>
    <h3 class="title-header noprint">Nombres Alternativos</h3>
    <div class="mt-5">
      <AlternativeNames :allows_crud="allows_crud"></AlternativeNames>
    </div>
  </div>
</template>
<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "AlternativeNamesView",
  components: {
    AlternativeNames: () => import("./AlternativeNames.vue"),
  },

  data() {
    return {};
  },

  computed: {
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "common.change_alternativenames"
      );
      if (has_permission) return has_permission;
      else return false;
    },
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.title-header {
  padding: 0 2rem;
  margin-top: 1em;
}
</style>